@import "./commonSCSS/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Freehand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Quicksand";
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
  text-rendering: optimizeSpeed;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  font-synthesis: none;
  -moz-font-feature-settings: "kern";
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: $white;
  font-family: "Roboto", sans-serif;
  height: 100%;
  margin: 0px;
  padding: 0px;
  @include fontSize(16px);
  overflow-x: hidden;
  font-smooth: always;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

a {
  cursor: pointer;
}

link {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.text-md-center {
  @media (max-width: 992px) {
    text-align: center !important;
  }
}

.text-sm-center {
  @media (max-width: 768px) {
    text-align: center !important;
  }
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.align-self-center {
  align-self: center !important;
}

.d-none {
  display: none !important;
}

.df {
  display: flex !important;
}

// Margins
@for $i from 0 to 30 {
  .ml-#{$i} {
    margin-left: 4px * $i !important;
  }

  .mr-#{$i} {
    margin-right: 4px * $i !important;
  }

  .mt-#{$i} {
    margin-top: 4px * $i !important;
  }

  .mb-#{$i} {
    margin-bottom: 4px * $i !important;
  }

  .mx-#{$i} {
    margin-left: 4px * $i !important;
    margin-right: 4px * $i !important;
  }

  .my-#{$i} {
    margin-top: 4px * $i !important;
    margin-bottom: 4px * $i !important;
  }

  .m-#{$i} {
    margin-top: 4px * $i !important;
    margin-bottom: 4px * $i !important;
    margin-left: 4px * $i !important;
    margin-right: 4px * $i !important;
  }
}

.mx-3 {
  margin-right: 16px !important;
  margin-left: 10px !important;
}

// Paddings
@for $i from 0 to 30 {
  .pl-#{$i} {
    padding-left: 4px * $i !important;
  }

  .pr-#{$i} {
    padding-right: 4px * $i !important;
  }

  .pt-#{$i} {
    padding-top: 4px * $i !important;
  }

  .pb-#{$i} {
    padding-bottom: 4px * $i !important;
  }

  .px-#{$i} {
    padding-left: 4px * $i !important;
    padding-right: 4px * $i !important;
  }

  .py-#{$i} {
    padding-top: 4px * $i !important;
    padding-bottom: 4px * $i !important;
  }

  .p-#{$i} {
    padding-top: 4px * $i !important;
    padding-bottom: 4px * $i !important;
    padding-left: 4px * $i !important;
    padding-right: 4px * $i !important;
  }
}

// Buttons
.ctaBtn {
  button {
    font-family: quicksand;

    @media (max-width: 900px) {
      padding: 12px 20px !important;
      font-size: 14px;
      line-height: 15px;
    }

    @media (max-width: 600px) {
      font-size: 12px !important;
      line-height: 15px;
      padding: 10px !important;
    }
  }

  .backbtn {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #8e282b;
    border: 1px solid #8e282b;
    width: 136px;
    height: 46px;
    border-radius: 4px;

    &:hover {
      background-color: unset;
    }
  }

  .nextbtn {
    @extend .backbtn;
    color: #fff;
    background: #8e282b;

    &:hover {
      color: #8e282b;
    }
  }

  .dotted {
    border: 3px dotted #8e282b;
    border-radius: 8px;
    background: transparent;
    padding: 16px 24px;
    font-size: 16px;
    font-weight: 500;
    color: $primary;
  }

  .success {
    text-align: center;
    height: 100px;
    width: 200px;
    width: 100%;
  }

  .whiteBtn {
    background: $white;
    @include border-radius(10px);
    color: $primary;
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    @include fontSize(16px);
    text-transform: capitalize;
    padding: 10px 40px;
    box-shadow: none;
    position: relative;
    // min-width: 130px;

    &.fixedbtn {
      position: fixed;
      right: 36%;
      bottom: 1.5%;
    }

    &:hover {
      background: lighten($color: $primary, $amount: 8%);
      color: $white;
    }

    &.xsbtn {
      background: $white;
      min-width: 24px;
      padding: 0;
      height: 24px;
      width: 24px;
      border-radius: 4px;
      font-size: 10px;
      opacity: 0.75;

      &:hover {
        background: $white;
        color: $primary;
        opacity: 0.85;
      }
    }
  }

  .submitBtn {
    background: $primary;
    @include border-radius(10px);
    color: $white;
    font-style: normal;
    font-weight: 500;
    @include fontSize(16px);
    text-transform: capitalize;
    padding: 18px 34px;
    box-shadow: none;
    position: relative;
    // min-width: 130px;
    font-family: "Quicksand";

    @media (max-width: 600px) {
      padding: 7px 15px;
      // min-width: unset;
    }

    &.xsm {
      padding: 6px 12px;
      // min-width: unset;
    }

    .icon {
      display: flex;
      align-items: center;
    }

    .mdBtn {
      @extend .submitBtn;
      font-weight: 500;
      padding: 18px 34px;

      @media (max-width: 600px) {
        padding: 7px 15px;
        // min-width: unset;
      }
    }

    &:hover {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      background: lighten($color: $white, $amount: 8%);
      color: $primary;
    }
  }

  .mediumBtn {
    @extend .submitBtn;
    font-weight: 400;
    padding: 6px 18px;
  }

  .mdBtn {
    @extend .submitBtn;
    font-weight: 500;
    padding: 18px 34px;
  }

  .smBtn {
    @extend .submitBtn;
    @include fontSize(12px);
    font-weight: 400;
    padding: 4px 12px;
  }

  .rejectBtn {
    background: #eb5757;
    border-radius: 10px;
    color: $white;
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
    padding: 10px 40px;

    &:hover {
      background: lighten($color: #eb5757, $amount: 8%);
      color: $white;
    }
  }

  .borderBtn {
    background: $bg-light;
    border-radius: 10px;
    color: $primary;
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 600;
    @include fontSize(16px);
    outline: 1px solid $primary;
    outline-offset: -1px;
    text-transform: capitalize;
    padding: 10px 17px;
    // min-width: 130px;
    box-shadow: none;
      @media (max-width: 900px) {
    font-weight: 500;

      }
    &:hover {
      background: $primary;
      // background: lighten($color: $primary, $amount: 8%);
      outline: 1px solid transparent;
      color: $white;
    }

    &.shopButton {
      @media (max-width: 600px) {
        border-radius: 6px;
        font-size: 9px !important;
        padding: 4px 2px !important;
        line-height: 12px;
      }
      @media (max-width: 900px) {
        font-size: 12px;
        padding: 5px 7px !important;
        border-radius: 6px;
      }
    }

    // @media (max-width: 600px) {
    //   padding: 7px 15px;
    //   // min-width: unset;
    // }

    &.xsm {
      padding: 6px 12px;
      background: transparent;
      // min-width: unset;

      &:hover {
        color: $primary;
        outline: 1px solid $primary;
      }
    }

    &.smBtn {
      @extend .borderBtn;
      @include fontSize(12px);
      font-weight: 400;
      padding: 4px 12px;
    }

    &.viewMore {
      padding: 18px 34px;

      @media (max-width: 600px) {
        padding: 7px 15px;
        // min-width: unset;
      }
    }

    &.white {
      color: #000;
      background: transparent;
    }
  }

  .cancelBtn {
    background: $white;
    @include border-radius(10px);
    color: #252525;
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    @include fontSize(16px);
    text-transform: capitalize;
    padding: 9px 39px;
    box-shadow: none;
    position: relative;
    // min-width: 130px;
    outline: 1px solid #6b62dc;
    outline-offset: -1px;

    &:hover {
      background: #6b62dc;
      color: $white;
    }
  }

  // loader
  .MuiCircularProgress-root {
    width: 25px !important;
    height: 25px !important;
  }
}

.inputField {
  text-align: left;

  .MuiOutlinedInput-root {
    height: 46px; // full input field
    border-radius: 8px;

    .MuiOutlinedInput-notchedOutline {
      border-color: $primary; // border on focus

      &:hover {
        border-color: $primary;
      }
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: $primary;
        border-width: 2px;
      }
    }
  }

  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
  .MuiInputLabel-formControl {
    top: -7px; // placeholder
    font-family: Quicksand;
    color: #a7a7a7;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    &.Mui-focused {
      color: $primary; // label on focus
      top: 0;

      .MuiOutlinedInput-notchedOutline {
        border-width: 2px;
      }
    }
  }

  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: $primary; // label on focus
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-notchedOutline {
    border-color: $primary; // border on focus
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline:hover {
    border-color: $primary;
  }

  // TextArea
  .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 8px;
  }

  .error {
    color: #8e282b;
    font-size: 12px;
  }
}

// Checkbox
.checkBox {
  display: block;
  font-family: Quicksand;

  input {
    display: none;
  }

  label {
    position: relative;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    line-height: 16px;
    font-size: 14px;

    &:before {
      content: "";
      @include border-radius(4px);
      background-color: transparent;
      border: 0.5px solid #8e282b6b;
      @include abs-position(13px, null, null, 13px);
      height: 16px;
      width: 16px;
      cursor: pointer;
    }

    .css-ahj2mt-MuiTypography-root {
      font-family: Quicksand;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }
  }
}

.radio_input {
  padding: 0;
  margin: 0 !important;

  > span {
    padding: 0;

    &.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
      color: $primary;
    }

    &.css-1a5icme.Mui-checked {
      color: $primary;
    }
  }
}

.loading {
  .loader {
    height: 45px;
    width: 45px;
    color: $primary;
  }
}

.inputTextfield {
  text-align: left;

  .error {
    color: #8e282b;
    font-size: 12px;
  }

  > textarea {
    font-family: Quicksand;
    color: #3d3d3d;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    width: 100%;
    height: auto;
    min-height: 85px;
    max-height: 120px;
    padding: 11px 16px;
    border: 1px solid #8e282b;
    border-radius: 8px;
    resize: none;
    box-sizing: border-box;

    &::placeholder {
      color: #a7a7a7;
      font-weight: 400;
    }

    &:hover {
      border: 1px solid $primary;
    }

    &:focus-visible,
    &:focus {
      outline: 1px solid $primary;
    }
  }
}

.img_uploader {
  position: relative;

  .uploader_input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(0%, -50%);
    width: 58px;
    height: 52px;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
  }

  .uploader_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(0%, -50%);
    cursor: pointer;
    z-index: 0;
  }
}

.header_grid {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.cart-icon {
  position: relative;
  width: 30px;
  /* Adjust the size if needed */
}

.cart-badge {
  position: absolute;
  top: 10px;
  left: 44px;
  background-color: $primary;
  color: white;
  padding: 1px 3px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;

  @media (max-width: 600px) {
    top: 0px;
    left: 35px;
  }
}

.product-image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;

  .mainPic {
    width: 100%;
    transition: transform 1.3s ease-in-out;
    transform-origin: center;
  }

  .mainPic:hover {
    transform: scale(1.1);
  }
}

.quick-view {
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    height: 20px;
    width: 20px;
  }
}

.product-image-container:hover .quick-view {
  opacity: 1;
  // height: 30px; width: 30px;
}

.quick-view img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.quick_view_modal {
  .h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 24px;

    @media (max-width: 900px) {
      font-size: 30px;
      line-height: 35px;
    }

    @media (max-width: 600px) {
      font-size: 25px;
      line-height: 30px;
    }
  }

  .h2 {
    font-size: 26px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0.03em;
    margin-bottom: 16px;

    @media (max-width: 900px) {
      font-size: 25px;
      line-height: 30px;
    }

    @media (max-width: 600px) {
      font-size: 20px;
      line-height: 25px;
    }

    span {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.03em;
      color: #a0a0a0;
      padding-left: 16px;
      text-decoration: line-through;

      @media (max-width: 600px) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  .colorss {
    display: flex;
    align-items: center;

    > h6 {
      font-size: 15px;
      font-weight: 800;
      line-height: 24px;
      color: #0c0c0c;
      margin-right: 24px;
    }

    .color_all {
      height: 27px;
      width: 27px;
      margin-right: 8px;
      border-radius: 50%;
      cursor: pointer;
      border: 1px solid #e9e9e9;

      &.selected {
        border: 1px solid #bbb8b8;
        box-shadow: 0 0 4px #1d1c1c;
        transform: scale(1.1);
      }
    }
  }

  .sizes {
    display: flex;
    align-items: center;

    > h6 {
      font-size: 15px;
      font-weight: 800;
      line-height: 24px;
      color: #0c0c0c;
      margin-right: 4px;
    }

    .size_all {
      padding: 5px 6px;
      color: $primary;
      background: $bg;
      margin-right: 12px;
      border-radius: 6px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.01em;
      text-transform: uppercase;

      &.selected {
        color: #fff;
        background: $primary;
      }
    }

    @media (max-width: 768px) {
      .size_all {
        padding: 7px 5px; /* Adjust padding on mobile */
      }
    }

    @media (max-width: 480px) {
      .size_all {
        padding: 6px 5px; /* Adjust padding for very small screens */
      }
    }
  }

  .desc {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #6c6c6c;
    margin-bottom: 24px;

    span {
      font-weight: 600;
      color: #8e282b;
    }
  }
}

.drawerContainer {
  width: 300px;
  padding: 20px;
}

.drawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.colorCircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
}

.sliderLabel {
  text-align: center;
}

.header_trans {
  position: fixed;
  top: -36px;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: top 1.3s ease-in-out;
  height: 160px;

  @media (max-width: 750px) {
    height: 60px;
    top: 0;
  }

  &.visible {
    top: 0;
  }
}

.root_trans {
  padding-top: 160px;

  @media (max-width: 750px) {
    padding-top: 60px;
  }
}

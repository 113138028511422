@import "mixins.scss";

// .userHeader {
//     position: relative;
//     z-index: 999;

//     .headContainer {
//         display: flex;
//         justify-content: center;

//         .header_grid {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             border-right: 1px solid #FFCACC;
//             padding: 24px;
//             border-top: none;
//             border-bottom: none;
//             border-left: none;

//             @media (max-width: 900px) {
//                 &.sale {
//                     display: none;
//                 }
//             }

//             @media (max-width: 600px) {
//                 padding: 12px;
//             }

//             img {
//                 cursor: pointer;
//             }

//             .p_black {
//                 font-size: 16px;
//                 line-height: 20px;
//                 font-weight: 500;
//                 color: #000000;
//                 cursor: pointer;
//             }

//             .p_red {
//                 font-size: 16px;
//                 line-height: 20px;
//                 font-weight: 600;
//                 color: $primary;
//             }
//         }
//     }

//     .hrTag {
//         border: none;
//         border-top: 1px solid rgba(142, 40, 43, 0.38);
//     }

//     .headBottom {
//         display: flex;
//         justify-content: space-between;

//         @media (max-width: 900px) {
//             padding: 0 40px;
//         }

//         @media (max-width: 600px) {
//             display: none;
//         }

//         .logo_section {
//             display: flex;
//             margin-left: 120px;

//             @media (max-width: 900px) {
//                 margin-left: 0;
//             }

//             >div {
//                 cursor: pointer;
//             }
//         }

//         .right_section {
//             margin-right: 120px;
//             align-items: center;
//             display: flex;
//             column-gap: 24px;
//             row-gap: 12px;

//             @media (max-width: 900px) {
//                 margin-right: 0;
//             }

//             .p_black {
//                 font-size: 16px;
//                 line-height: 20px;
//                 font-weight: 500;
//                 color: #000000;
//                 cursor: pointer;
//             }

//             .dropButton {
//                 &:hover {
//                     background-color: unset;
//                 }
//             }
//         }
//     }
// }

.bg_image {
  background: #f8f8f8;
  background-image: url("../../public/images/footer_white.png");
  min-height: 220px;
  height: 100%;

  @media (max-width: 600px) {
    display: none;
  }
}

.userFooter {
  padding: 0 10px;
  background: #f8f8f8;
  position: relative;
  z-index: 999;

  @media (max-width: 600px) {
    margin-top: 12px;
  }

  @media (min-width: 600px) {
    padding: 0 40px;
  }

  width: 100%;

  @media (min-width: 1500px) {
    padding: 0 120px;

    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: -240px;
    //     left: 0;
    //     background: url("../../public/images/footer_flower_bg.svg") no-repeat;
    //     z-index: 0;
    //     width: 100px;
    //     height: 230px;
    // }
    // &::after {
    //     content: "";
    //     position: absolute;
    //     top: -350px;
    //     right: 0;
    //     background: url("../../public/images/footer_flower_bg_right.svg") no-repeat;
    //     z-index: 0;
    //     width: 195px;
    //     height: 370px;
    // }
  }

  .footer_grid {
    h2 {
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      margin-bottom: 24px;

      @media (max-width: 600px) {
        font-size: 16px;
        margin-bottom: 16px;
        line-height: 18px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      font-family: Quicksand;
      margin-bottom: 16px;
      width: fit-content;
      cursor: pointer;

      @media (max-width: 600px) {
        font-size: 12px;
        margin-bottom: 10px;
        line-height: 15px;
      }
    }

    .para {
      font-size: 14px;
      font-weight: 300;
      line-height: 20.72px;
    }

    .divider {
      border: none;
      border-top: 1px solid $primary;
      max-width: 700px;
      margin: 0 auto;
    }

    .emailaddr {
      position: relative;

      > input {
        font-size: 16px;
        font-weight: 400;
        line-height: 23.68px;
        padding: 10px 24px;
        padding-right: 55px;
        background: #fff;
        border: none;
        border-radius: 12px;
        width: 100%;
        color: #2d2d2d;

        &:focus-visible {
          outline: 1px solid $primary;
        }

        &::placeholder {
          color: #2d2d2d;
        }
      }

      > img {
        position: absolute;
        right: 24px;
        top: 14px;
      }
    }
  }

  .copy {
    display: flex;
    justify-content: center;

    p.copyright {
      font-family: Quicksand;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      padding: 24px;

      > span {
        color: $primary;
        font-weight: 700;
        cursor: pointer;
      }

      @media (max-width: 600px) {
        font-size: 12px;
        padding: 20px 10px;
      }
    }
  }
}

// MENU CONTENT CSS
.profileDropMenu {
  > div {
    > ul {
      padding: 0;

      .profile_drop_content {
        padding: 40px;
        border-radius: 12px;
        box-shadow: -8px 19px 17.6px 0px #0000000f;
        background-color: #fff;
        min-width: 300px;

        .wc {
          font-size: 24px;
          font-weight: 700;
          line-height: 30px;
        }

        .acn_btn {
          .login {
            cursor: pointer;
            border: 1px solid $primary;
            color: $primary;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            padding: 12px;
            border-radius: 2px;
          }
        }

        .common_page {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          color: #2d2d2d;
          cursor: pointer;

          &.logout {
            color: $primary;
          }

          &.sub {
            color: #666666;
            cursor: default;
          }
        }

        .hrTag {
          border-bottom: 1px solid #ffcacc;
        }
      }
    }
  }
}

.header2 {
  header {
    .top-bar {
      background-color: #a71635c4;
      color: white;
      padding: 8px 20px;
      display: flex;
      justify-content: center;

      .body1 {
        font-weight: 400;
        font-size: 15px;
      }

      .body2 {
        font-weight: 200;
      }
    }

    .header {
      background-color: white;
      color: black;
      box-shadow: none;
      border-bottom: 1px solid #eaeaea;

      .toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eaeaea;

        .menu-icon {
          background: transparent;
          border: unset;
          cursor: pointer;
        }

        .logo-container {
          position: absolute; /* Position it absolutely within the toolbar */
          left: 50%; /* Move it to the center horizontally */
          transform: translateX(
            -50%
          ); /* Center it properly by shifting it left by 50% of its own width */
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 0;

          .logo {
            height: 42px;
            cursor: pointer;
          }
        }

        .icons-container {
          display: flex;
          align-items: center;
          gap: 25px;
          margin-left: auto; /* Push the icons container to the right */
@media (max-width: 748px) {
            gap: 30px;
          }

          @media (max-width: 600px) {
            gap: 10px;
          }
          > button {
            color: $primary;
            background: transparent;
            border: unset;
            outline: unset;
            cursor: pointer;

            .MuiBadge-badge {
              background-color: $primary;
              top: -2px;
              right: -3px;
              @media (max-width: 748px) {
                right: 0;
                font-size: 10px;
                padding: 4px;
                height: 17px;
                min-width: 17px;
              }
              @media (max-width: 600px) {
                right: 2px;
                font-size: 8px;
                padding: 3px;
                height: 15px;
                min-width: 15px;
              }
            }
          }
        }
      }

      .nav_links {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 35px;
        padding: 0 20px;
        min-height: 60px;

        @media (max-width: 1050px) {
          gap: 20px;
        }

        .nav_link {
          font-size: 14px;
          font-weight: 400;
          font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
            Ubuntu, Helvetica Neue, sans-serif, Apple Color Emoji,
            Segoe UI Emoji, Segoe UI Symbol;
          text-transform: uppercase;
          cursor: pointer;
          position: relative;
          padding: 5px 10px;
          @media (max-width: 950px) {
           padding: 5px;
           font-size: 11px;
          }

          &:hover {
            color: $primary; // Replace with your primary hover color

            .dropdown {
              display: flex; // Show dropdown on hover
            }
          }

          .dropdown {
            display: none; // Initially hidden
            position: absolute;
            top: 100%;
            left: 0;
            background-color: #fff;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
            border: 1px solid #eaeaea;
            padding: 30px 20px;
            z-index: 1000;
            flex-direction: row;
            justify-content: space-between;
            width: 900px;
            max-width: 900px;

            .coll_img {
              display: flex;
              gap: 25px;

              .dropdown-item {
                position: relative;
                overflow: hidden;
                border-radius: 8px;

                .dropdown-img {
                  width: 100%;
                  max-height: 400px;
                  object-fit: cover;
                  transition: transform 0.8s ease-in-out;
                  border-radius: inherit;
                }

                .dropdown-img:hover {
                  transform: scale(1.1);
                }

                .coll_name {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  font-size: 16px;
                  font-weight: 600;
                  color: #fff;
                  text-transform: capitalize;
                  z-index: 2;
                }
              }
            }

            .dropdown-column {
              display: flex;
              flex-direction: column;
              flex: 1;
              margin-right: 20px; // Add spacing between columns

              &:last-child {
                margin-right: 0; // Remove margin for the last column
              }

              .dropdown-heading {
                font-size: 13px;
                font-weight: bold;
                text-transform: uppercase;
                color: #333;
                padding: 13px 0;
                margin-bottom: 10px;
                border-bottom: 1px solid #eaeaea;
              }

              .dropdown-item {
                font-size: 12px;
                font-weight: 400;
                color: #808080;
                padding: 5px 0;
                cursor: pointer;
                text-transform: capitalize;
                transition: color 0.3s ease;

                &:hover {
                  color: #333; // Replace with your primary hover color
                }
              }
            }
          }
        }
      }
    }
  }
}

.mobile-menu-header {
  width: 400px;

  .drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #ffffff;
    border-bottom: 1px solid #e0e0e0;

    h6 {
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }

    .MuiIconButton-root {
      color: #333;
    }
  }

  .drawer-list {
    padding: 0;

    .MuiListItem-root {
      padding: 12px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: #333;
      cursor: pointer;
      border-bottom: 1px solid #f0f0f0;

      &:hover {
        background-color: #f9f9f9;
      }

      .MuiListItemText-root {
        font-size: 16px;
        font-weight: bold;
      }

      .tag-green {
        background-color: #228b22;
        color: #fff;
        padding: 3px 8px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 12px;
      }

      .tag-yellow {
        background-color: #ffd700;
        color: #333;
        padding: 3px 8px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 12px;
      }

      .MuiSvgIcon-root {
        font-size: 20px;
        color: #555;
      }
    }

    .nested-list {
      padding-left: 20px;
      background-color: #f7f7f7;

      .MuiListItem-root {
        padding: 8px 16px;
        font-size: 14px;
        font-weight: normal;
        color: #555;

        &:hover {
          background-color: #eaeaea;
        }
      }
    }
  }

  .contact-section {
    margin: 20px;
    padding: 15px;
    background-color: #fff;
    border-top: 1px solid #e0e0e0;

    p {
      margin: 0;
      font-size: 14px;

      &:first-child {
        font-weight: bold;
        color: #333;
      }

      &:last-child {
        margin-top: 5px;
        color: #555;
      }
    }
  }
}

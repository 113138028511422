@import '../../commonSCSS/mixins.scss';

.tableHeight {
  height: 100%;
  max-height: 760px;
  min-height: 560px;
  // min-height: 400px;
}

.filterBar {
  display: flex;
  justify-content: center;
  position: relative;
  left: 0px;
  width: 30px;
  z-index: 91;

  button {
    // background: transparent;
    background: $blue-light;
    @include border-radius(4px 0px 0px 4px);
    min-width: 25px;
    padding: 4px;
    height: 56px;

    &:hover {
      background: rgba($primary-light, 0.6);
    }

    .bars {
      background: $primary;
      @include border-radius(6px);
      display: block;
      position: relative;
      top: -5px;
      height: 3px;
      width: 3px;

      &:before,
      &:after {
        background: $primary;
        @include border-radius(6px);
        content: "";
        height: 3px;
        width: 3px;
      }

      &:before {
        @include position(absolute, $top: 6px, $right: 0px);
      }

      &:after {
        @include position(absolute, $top: 12px, $right: 0px);
      }
    }

    @include themed {
      background: $bg-grey;
    }
  }

  .showColumns {
    background: $white;
    @include box-shadow(0px 4px 20px rgba($bg-dark, 0.2));
    @include border-radius(8px);
    @include position(absolute, $top: 56px, $left: 0px);
    padding: 4px 0px;
    height: 390px;
    min-width: 280px;
    width: 280px;
    z-index: 99;

    // overflow: scroll;
    @include themed() {
      @include box-shadow(0px 4px 20px rgba($black, 0.6));
      background: $bg-grey;
    }

    .findInput {
      border-bottom: 1px solid $primary-light;
      padding: 0px 12px;

      @include themed() {
        border-color: $bg-dark;
      }

      input {
        background: transparent;
        border: none;
        @include fontSize(14px);
        font-weight: 400;
        height: 40px;

        &:focus {
          outline: none;
        }

        @include themed() {
          color: $white;
        }
      }
    }

    .rahul {
      height: 319px;
      overflow-y: scroll;
      overflow-x: hidden;
      position: relative;
      padding: 0px 12px 0px 0px;
      @include fontSize(14px);

      @include themed() {
        color: $text-light;
      }

      p {
        @include fontSize(14px);
      }

      .checkBox {
        &:hover {
          cursor: pointer;
        }
      }

      .MuiCheckbox-root {
        padding: 6px;

        svg {
          @include themed() {
            color: $text-grey;
          }
        }

        &.Mui-checked {
          svg {
            color: $primary;
          }
        }
      }
    }

    .hideShowComulns {
      background: $blue-light;
      @include border-radius(0px 0px 8px 8px);
      display: flex;
      justify-content: space-between;
      padding: 6px 12px;

      @include themed() {
        background: $bg-dark;
      }

      a {
        text-decoration: none;
        @include fontSize(12px);
        color: $primary;
      }
    }
  }
}

.dataList {
  border: none !important;
  font-size: 12px !important;

  @include themed() {
    background: $black;
  }

  .MuiDataGrid-main {
    left: -30px;
    width: calc(100% + 30px);

    .MuiDataGrid-virtualScroller {
      .MuiDataGrid-virtualScrollerContent {
        height: 500px !important;
      }
    }


    @include themed() {
      background: $black;
      color: $text-grey;
      border-radius: 4px 4px 0 0;
    }

    .MuiDataGrid-columnHeaders {
      background: rgba($primary-light, 0.5);
      border: none;
      padding-left: 30px;

      @include themed() {
        background: $bg-grey;
        color: $text-grey;
      }

      .MuiDataGrid-columnHeaderCheckbox {
        .MuiDataGrid-columnHeaderTitleContainer {
          justify-content: center;
        }
      }

      .MuiDataGrid-checkboxInput {
        svg {
          height: 18px;
          width: 18px;

          @include themed() {
            color: $text-grey;
          }
        }

        &.Mui-checked {
          svg {
            color: $primary;
          }
        }
      }

      .MuiDataGrid-columnHeaderTitleContainer {
        justify-content: space-between;
      }

      .MuiDataGrid-iconButtonContainer {
        .MuiIconButton-root {
          background: $white;
          padding: 2px 0px;
          height: 20px;
          width: 20px;

          &:hover {
            svg {
              opacity: 1;
            }
          }

          svg {
            color: $primary;
            opacity: 1;
            height: 16px;
          }
        }
      }

      .MuiDataGrid-columnSeparator {
        svg {
          width: 15px;
        }
      }

      .MuiDataGrid-menuIcon {
        .MuiIconButton-root {
          padding: 2px;
        }

        svg {
          color: $text-grey;
        }
      }
    }

    .MuiDataGrid-row {
      @include box-shadow(0px 0px 12px rgba(#000, 0.1));
      margin-top: 8px;
      padding-left: 30px;

      @include themed() {
        background: $bg-dark;
        color: $text-light;
      }

      &:hover {
        background: lighten($primary-light, 2%);

        @include themed() {
          background: $bg-grey;
        }
      }

      .MuiDataGrid-cell {
        border: none;
        padding-top: 4px;
        padding-bottom: 4px;

        .MuiDataGrid-checkboxInput {
          svg {
            height: 18px;
            width: 18px;

            @include themed() {
              color: $text-grey;
            }
          }

          &.Mui-checked {
            svg {
              color: $primary;
            }
          }
        }
      }
    }
  }

  .MuiDataGrid-footerContainer {
    border: none;
    margin-right: 70px;
    margin-top: 50px;

    @include themed() {
      background: $bg-dark;
      color: $text-light;
    }

    .MuiTablePagination-root {
      @include themed() {
        color: $text-light;
      }
    }

    .MuiTablePagination-actions {
      .Mui-disabled {}
    }

    .MuiTablePagination-actions {
      // background: red;

      .MuiButtonBase-root {
        @include themed {
          svg {
            color: #ddd;
          }
        }

        &.Mui-disabled {
          @include themed {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

// .App {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100vh;
// }

.list-container {
  display: flex;
  font-size: 18px;
  background-color: #eee;
  flex-direction: column;
}

.item-container {
  background-color: $white;
  border: 1px solid black;
  padding: 25px 70px;
  margin: 15px 50px;
}

.draggableItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 300;
  background-color: white;

  .MuiCheckbox-root {
    padding: 6px;

    svg {
      @include themed() {
        color: $text-grey;
      }
    }

    &.Mui-checked {
      svg {
        color: $primary;
      }
    }
  }
}
.banner-slider {
  position: relative;
  width: 100%;
  overflow: hidden;

  .logo {
    position: absolute;
    top: 55px;
    left: 65px;
    z-index: 2;

    img {
      width: 120px;
      /* Reduced size */
      height: auto;
    }
  }

  .top-right-navigation {
    position: absolute;
    top: 65px;
    right: 50px;
    z-index: 2;

    .nav-btn {
      margin-left: 15px;
      font-weight: bold;
      color: #000;
      background-color: transparent;
      border: none;
      font-size: 14px;
      /* Reduced size */
      cursor: pointer;

      &:hover {
        color: #b02e2e;
      }
    }

    &.white-menu .nav-btn {
      color: #fff;

      &:hover {
        color: #f1c40f;
      }
    }
  }

  .slider-item {
    position: relative;

    .slider-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .slider-content {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 20px 0px;
      z-index: 1;

      h1 {
        font-size: 60px;
        /* Reduced size */
        font-weight: bold;
        margin: 0 0 10px;
        font-family: Oleo Script;
        color: #000;
      }

      p {
        font-size: 1.2rem;
        /* Reduced size */
        margin: 10px 0 20px;
        color: #000;
      }

      .shop-now-btn {
        background-color: #b02e2e;
        color: #fff;
        padding: 10px 20px;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: #900;
        }
      }

      &.center-text {
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
      }

      &.left-text {
        left: 5%;
        text-align: left;
      }

      &.black-text {
        color: #000;

        p {
          color: #000;
          font-family: Oleo Script;
          font-size: 36px;
        }
      }

      &.white-text {
        color: #fff;

        h1,
        p {
          color: #fff;
        }
      }
    }
  }

  /* Slick dots styles */
  .slick-dots {
    bottom: 20px;

    li {
      margin: 0 5px;

      button {
        border: none;

        &::before {
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: transparent;
          border: 2px solid #b02e2e;
          transition: background-color 0.3s ease, border-color 0.3s ease;
        }
      }

      &.slick-active button::before {
        background-color: #b02e2e;
        border: 2px solid #b02e2e;
      }
    }
  }

  /* Responsive adjustments */

  /* For screens up to 1024px */
  @media (max-width: 1024px) {
    .logo {
      top: 40px;

      /* Reduced top spacing */
      img {
        width: 100px;
        /* Reduced logo size */
      }
    }

    .top-right-navigation {
      top: 40px;
      /* Reduced top spacing */
      right: 40px;

      .nav-btn {
        font-size: 12px !important;
        /* Reduced nav text size */
      }
    }

    .slider-content h1 {
      font-size: 48px !important;
      /* Reduced heading size */
    }

    .slider-content p {
      font-size: 1rem !important;
      /* Further reduced paragraph size */
    }
  }

  /* For screens up to 768px */
  @media (max-width: 768px) {
    .logo {
      top: 35px;
      /* Reduced top spacing */
      left: 35px;

      img {
        width: 80px;
        /* Further reduced logo size */
      }
    }

    .top-right-navigation {
      top: 35px;
      right: 35px;

      .nav-btn {
        font-size: 10px !important;
        /* Further reduced nav text size */
      }
    }

    .slider-content h1 {
      font-size: 36px !important;
      /* Further reduced heading size */
    }

    .slider-content p {
      font-size: 0.9rem !important;
      /* Further reduced paragraph size */
    }
  }

  /* For screens up to 600px (adjust for image change below 600px) */
  @media (max-width: 600px) {
    .logo {
      top: 30px;
      /* Adjusted for small screens */
      left: 30px;

      img {
        width: 60px;
        /* Smallest logo size */
      }
    }

    .top-right-navigation {
      top: 30px;
      right: 30px;

      .nav-btn {
        font-size: 8px !important;
        /* Smallest nav text size */
      }
    }

    .slider-content h1 {
      font-size: 24px !important;
      /* Smallest heading size */
    }

    .slider-content p {
      font-size: 0.8rem !important;
      /* Smallest paragraph size */
    }

    .slider-item {
      // Default styles for the slides
      position: relative;

      // For screens up to 600px
    }
  }
  @media (min-width: 701px) {
    .slider-image {
      display: block !important;
      /* Show desktop images */
    }
  
    .slider-image-mobile {
      display: none !important;
      /* Hide mobile-specific images */
    }
  }
  
  @media (max-width: 700px) {
    .logo {
      top: 30px;
      /* Adjusted for small screens */
      left: 30px;
  
      img {
        width: 60px;
        /* Smallest logo size */
      }
    }
  
    .top-right-navigation {
      top: 30px;
      right: 30px;
  
      .nav-btn {
        font-size: 8px !important;
        /* Smallest nav text size */
      }
    }
  
    .slider-item {
      position: relative;
  
      .slider-image {
        display: none !important;
        /* Hide desktop images */
      }
  
      .slider-image-mobile {
        display: block !important;
        /* Show mobile-specific images */
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  
      .slider-content {
        position: absolute;
        top: 30% !important;
        /* Centered at the top */
        text-align: center !important;
        transform: translateX(-50%);
        text-align: center;
        z-index: 2;
  
        h1 {
          font-size: 40px !important;
          /* Smaller heading */
        }
  
        p {
          font-size: 18px !important;
          /* Smaller text */
        }
      }
    }
  }
  
  @media (max-width: 450px) {
    .slider-item {
  
      .slider-content {
        position: absolute;
        top: 30% !important;
        /* Centered at the top */
        text-align: center !important;
        transform: translateX(-50%);
        text-align: center;
        z-index: 2;
  
        h1 {
          font-size: 20px !important;
          /* Smaller heading */
        }
  
        p {
          font-size: 12px !important;
          /* Smaller text */
        }
      }
    }
  }
}


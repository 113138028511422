@import '../../commonSCSS/mixins.scss';

.loader-container {
    background: rgba(0, 0, 0, 0.80);
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    z-index: 9991;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);

    .loader {
        width: 130px;
        height: 130px;
        border: 8px solid rgb(88 88 88 / 65%);
        border-radius: 50%;
        position: relative;

        span {
            display: block;
            background: #959595;
        }

        .hour,
        .min {
            width: 6px;
            height: 44px;
            border-radius: 50px;
            position: absolute;
            top: 57px;
            left: 54px;
            animation: load9243 2s linear infinite;
            transform-origin: top center;
        }

        .min {
            height: 30px;
            animation: load9243 7s linear infinite;
        }

        .circel {
            width: 10px;
            height: 10px;
            border-radius: 50px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @keyframes load9243 {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }
}
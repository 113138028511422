// Color Variables
$bg: #FFEFEF;
$bg-light: rgba(255, 246, 247, 1);
$primary: #8E282B;
$white: #ffffff;
$primary-light: #e5e7fe;
$blue-light: #f1f6ff;
$grey-light: #D9D9D9;
$grey-dark: #3a4855;
$primary-red: #eb5757;
$primary-orange: #FFB547;
$text-light: #ddd;
$text-grey: #999;
$bg-grey: #333;
$bg-dark: #171b1e;
$black: #000;
$sky-blue: #3569B6;
$disable-red: rgba(#eb5757, 0.5);
$primary-yellow: #FFA412;
$text-dark:#30364D;

/*--------------Mixins------------------*/
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin border-radius($radius: none) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin appearance() {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  //font-size: $size;
  font-size: calculateRem($size);
}

@mixin position($position,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin abs-position ($top, $right, $bottom, $left) {
  // @include abs-position(10px, 10px, auto, auto);
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}


// Theme Color Change
$themes: (
  dark: ( // card-bg: $bg--card--rahul,
  ),
);

@mixin themed() {

  @each $theme,
  $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;

      @each $key,
      $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}
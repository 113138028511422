@import 'mixins.scss';

.modalWrapper {
    overflow-y: auto;
    backdrop-filter: blur(2px);
    background: rgba($black, 0.5);

    .MuiBackdrop-root {
        background: transparent;
    }

    .modalInner {
        position: relative;
        margin: 120px auto;
        width: 70%;
        @include border-radius(12px);
        background: $white;
        padding: 24px;
        &.quick_view_modal{
            height: 90vh;
            max-height: 900px;
            margin: auto;
            margin-top: 40px;
            @media (max-width: 900px) {
                width: calc(100% - 10px) !important;
            }
        }

        @media (max-width: 600px) {
            width: calc(100% - 50px);
        }

        &.md-width {
            width: 624px;
            max-width: 100%;
        }

        &.lg-width {
            width: 650px;
            max-width: 650px;
        }

.xl-width {
    width: 100%;
    max-width: calc(100% - 500px);
}

@media (max-width: 768px) {
    .xl-width {
        max-width: 100%;  /* Allow full width on mobile */
    }
}

@media (max-width: 480px) {
    .xl-width {
        max-width: 100%;  /* Ensure full width on very small screens */
    }
}


        &.mob {
            margin: 0 auto;
            width: 100%;
            background: $white;
            padding: 20px;
            @include border-radius(0px);
            height: 100%;
        }
        .quick_container{
            height: calc(100vh - 180px);
            // height: 550px;
            overflow-y: scroll;
        }

        .inputField {
            .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
                height: 46px;
            }

            .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
                font-size: 12px;
                top: -5px;
            }

            .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
                border-radius: 8px;
            }
        }

        .popup_heading {
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
        }

        .close_button {
            text-align: right;

            svg {
                height: 30px;
                width: 30px;
                color: #000;
            }
        }

        .divider {
            border-bottom: 1px solid #FFCACC;
        }

        .title {
            font-family: Quicksand;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
        }

        .success {
            font-size: 28px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: -0.30000001192092896px;
            margin-bottom: 12px;
        }

        .msg {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.30000001192092896px;
            color: #838595;
            margin-bottom: 12px;
        }

        &.searchModal {
            padding: 0;
            margin: 50px auto;

            .search_drop_content {
                padding: 12px 24px;

                .searrch {
                    position: relative;

                    .icon {
                        height: 13px;
                        width: 13px;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);

                    }

                    .pro_search {
                        border: none;
                        height: 40px;
                        padding-left: 20px;
                        color: $primary;
                        font-family: Quicksand;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20px;

                        &:focus-visible {
                            outline: none;
                        }

                        &::placeholder {
                            color: $primary;
                        }
                    }
                }

                .trending {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 20px;
                    color: $primary;
                }

                .tSearch {
                    cursor: pointer;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 24px;

                    p {
                        @extend .trending;
                        font-weight: 600;
                        padding: 12px;
                        background: #FFF3F3;
                        border: 1px solid #8E282B33;
                        border-radius: 2px;

                        &:last-child {
                            margin: 0;
                        }

                        >img {
                            margin-left: 10px;
                        }
                    }
                }

                .div_height {
                    max-height: 400px;
                    overflow-x: hidden;
                    overflow-y: scroll;

                    &::-webkit-scrollbar {
                        width: 2px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: $primary;
                        border-radius: 2px;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: $bg;
                        border-radius: 2px;
                    }

                    .products {
                        cursor: pointer;
                        margin-bottom: 24px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        >img {
                            margin-right: 12px;
                            width: 72px;
                            height: 78px;
                            border-radius: 12px;
                            object-fit: cover;

                        }

                        .prodiv {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            >p {
                                font-size: 10px;
                                font-weight: 700;
                                line-height: 12.5px;

                                span {
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }


            }
        }

        .review_box {
            display: flex;

            .pro_img {
                border-radius: 8px;
                max-width: 60px;
                max-width: 60px;
            }

            .review_title {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-height: 50px;

                >h5 {
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 25px;

                }
            }
        }

        .galerry {
            display: flex;
            align-items: center;
            gap: 12px;
            margin-top: 20px;

            .title {
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                margin-left: 58px;
            }
        }

        .uploaded_images {
            display: flex;
            flex-wrap: wrap;
            row-gap: 8px;

            .im_box {
                margin-right: 12px;
                position: relative;

                .cross {
                    width: 60px;
                    height: 60px;
                    border-radius: 8px;
                }

                .cross-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 14px;
                    width: 14px;
                    background: #fff;
                    color: $primary;
                    border-radius: 0 0 0 5px;
                    cursor: pointer;
                }
            }

        }
    }
}